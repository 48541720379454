import React from 'react';
import PropTypes from 'prop-types';

import { createEmbeds, updateEmbedsWithFact } from './helper/index';

import Embed from './subcomponents/Embed';

const Embeds = ({ embedsCopy, embeds }) => {
  const embedsArray = createEmbeds(embedsCopy, embeds);
  const updatedEmbedsArray = updateEmbedsWithFact(embedsArray);

  return (
    <section>
      {updatedEmbedsArray.length > 0 &&
        updatedEmbedsArray.map(item => (
          <div key={item.id}>
            <Embed item={item} />
          </div>
        ))}
    </section>
  );
};

Embeds.propTypes = {
  embeds: PropTypes.arrayOf(PropTypes.shape()),
  embedsCopy: PropTypes.string
};

Embeds.defaultProps = {
  embeds: null,
  embedsCopy: ''
};

export default Embeds;
