/* eslint-disable no-shadow */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import { Image, VideoLoop, DataContext, AspectRatio } from '@ccg/core';

import {
  Text,
  Fact,
  Facts,
  InlineContact,
  Downloads,
  Video,
  Quote,
  MediaContent,
  Gallery,
  TeamList,
  Form,
  CategoryMarquee,
  SocialEmbed,
  SocialFeed,
  LogoWall,
  MediaRow,
  TagTeaser,
  TextTeasers
} from '@components';

import { withTranslation } from '../../../../i18n';

import { encodeMemberSlug, getNextLinkProps } from '../../../../helper';
import { formDataHelper } from '../helper/form';
import { LoginContext } from '../../../../contexts/LoginProvider';

import AutomaticTeaserContainer from '../../TeaserContainer/AutomaticTeaserContainer';
import ManualTeasers from '../../TeaserContainer/ManualTeasers';

const {
  publicRuntimeConfig: { ACTIVE_SITE_HANDLE, GOOGLE_RECAPTCHA_SITEKEY }
} = getConfig();

const Embed = ({ item, t }) => {
  const { __typename, ...props } = item;

  const {
    userData: { isGuest, userGroups }
  } = useContext(LoginContext);

  const { data } = useContext(DataContext);

  switch (__typename) {
    // Text
    case 'embeds_text_BlockType':
      return <Text {...props} hasGrid hasParallax reveal />;

    // Fact
    case 'embeds_fact_BlockType':
      return <Fact {...props} />;

    // Facts
    case 'embeds_facts_BlockType':
      return <Facts {...props} />;

    // Quote
    case 'embeds_quote_BlockType':
      return (
        <Quote
          {...props}
          author={
            props.author &&
            props.author.length > 0 &&
            props.author.map(({ photo, ...rest }) => ({
              ...rest,
              photo: photo && {
                src: photo.url,
                srcset: {
                  '3x': photo.url,
                  '2x': photo.width600,
                  '1x': photo.width300
                },
                alt: photo.alt
              }
            }))
          }
        />
      );

    // Inline Contact
    case 'embeds_inlineContact_BlockType':
      return (
        <InlineContact
          {...props}
          user={
            props.user &&
            props.user.map(({ socialLinks, photo, ...rest }) => ({
              ...rest,
              photo: photo && {
                src: photo.url,
                srcset: {
                  '3x': photo.url,
                  '2x': photo.width600,
                  '1x': photo.width300
                },
                alt: photo.alt
              },
              socialLinks:
                socialLinks &&
                socialLinks.map(({ linkTo, socialService }) => ({
                  socialService,
                  ...getNextLinkProps(linkTo)
                }))
            }))
          }
        />
      );

    // Image
    case 'embeds_image_BlockType':
      return (
        <MediaContent
          variant={props.size}
          caption={props.caption}
          credits={props.image[0].credits}
          hasFocalPoint={props.image[0].hasFocalPoint}
          focalPoint={props.image[0].focalPoint}
          inline={props.inline}
          shadow={props.shadow}
        >
          <Image
            src={props.image[0].url}
            alt={props.image[0].alt}
            sizes={props.size === 'l' ? '90vw' : '50vw'}
            ratio={`${props.image[0].width}:${props.image[0].height}`}
            srcset={{
              '1920w': props.image[0].url,
              '1440w': props.image[0].width1440,
              '960w': props.image[0].width960,
              '720w': props.image[0].width720,
              '375w': props.image[0].width375
            }}
          />
        </MediaContent>
      );

    // Iframe
    case 'embeds_iframe_BlockType':
      return (
        <MediaContent variant={props.size} caption={props.caption}>
          <AspectRatio ratio={props.ratio || '16:9'}>
            <iframe title={props.id} src={props.iframeUrl} allowFullScreen frameBorder="0" />
          </AspectRatio>
        </MediaContent>
      );

    // VideoLoop
    case 'embeds_videoLoop_BlockType':
      return (
        <MediaContent
          variant={props.size}
          caption={props.caption}
          credits={props.video[0].credits}
          inline={props.inline}
          shadow={props.shadow}
        >
          <VideoLoop src={props.video[0].url} autoPlay loop />
        </MediaContent>
      );

    // Video
    case 'embeds_video_BlockType':
      const { reference } = props;
      const [video] = reference;

      return video ? (
        <MediaContent
          variant={props.size}
          caption={video.caption}
          credits={video.credits}
          inline={props.inline}
        >
          <Video
            videoUrl={video.videoUrl}
            image={
              video.image[0] && {
                src: video.image[0].url,
                srcset: {
                  '1920w': video.image[0].url,
                  '1440w': video.image[0].width1440,
                  '960w': video.image[0].width960,
                  '720w': video.image[0].width720,
                  '375w': video.image[0].width375
                },
                alt: video.image[0].alt
              }
            }
          />
        </MediaContent>
      ) : null;

    // Gallery
    case 'embeds_gallery_BlockType':
      const galleryImages = props.reference[0]
        ? props.reference[0].gallery.map(item => ({
            src: item.image[0].url,
            srcset: {
              '1920w': item.image[0].url,
              '1440w': item.image[0].width1440,
              '960w': item.image[0].width960,
              '720w': item.image[0].width720,
              '375w': item.image[0].width375
            },
            id: item.image[0].id,
            alt: item.image[0].alt,
            width: item.image[0].width,
            height: item.image[0].height,
            hasFocalPoint: item.image[0].hasFocalPoint,
            focalPoint: item.image[0].focalPoint,
            caption: item.caption
          }))
        : [];

      return props.reference[0] ? (
        <Gallery
          size={props.size}
          images={galleryImages}
          imageShadow={props.imageShadow}
          caption={props.caption}
          title={t('gallery.title')}
          imagesTitle={t('gallery.imagesTitle')}
        />
      ) : null;

    // Media Row
    case 'embeds_mediaRow_BlockType':
      const mediaRowItems = props.items[0].itemBlocks.map(item => {
        if (item.__typename === 'itemBlocks_image_BlockType') {
          return {
            type: 'image',
            src: item.image[0].url,
            srcset: {
              '1920w': item.image[0].url,
              '1440w': item.image[0].width1440,
              '960w': item.image[0].width960,
              '720w': item.image[0].width720,
              '375w': item.image[0].width375
            },
            id: item.id,
            alt: item.image[0].alt,
            width: item.image[0].width,
            height: item.image[0].height,
            caption: item.caption
          };
        }

        if (item.__typename === 'itemBlocks_videoLoop_BlockType') {
          return {
            type: 'videoLoop',
            id: item.id,
            src: item.videoLoop[0].url,
            caption: item.caption
          };
        }

        return null;
      });

      return <MediaRow itemsPerRow={props.itemsPerRow} items={mediaRowItems} />;

    // Downloads
    case 'embeds_downloads_BlockType':
      const downloads = props.reference[0]
        ? props.reference
            .filter(item => {
              // allow if not restricted
              if (!item.userGroups.handles.length) {
                return true;
              }

              // default allow admin
              item.userGroups.handles.push('admin');
              // check if roles are included in current user groups
              const commonRoles = item.userGroups.handles.filter(role => userGroups.includes(role));
              return !isGuest && commonRoles.length;
            })
            .map(item => ({ title: item.title, file: item.file[0] }))
        : [];

      return props.reference[0] ? <Downloads downloads={downloads} /> : null;

    // TeamList
    case 'embeds_teamList_BlockType':
      const { members, linkTo, ...rest } = props;
      const updatedMembers = members
        .filter(item => {
          // allow if user is logged in or item is enabled
          return item.status === 'active' || !isGuest;
        })
        .map(member => ({
          id: member.id,
          name: member.name,
          title: member.alternativePosition,
          position: member.position,
          image: member.photo
            ? {
                url: member.photo.width212,
                srcset: {
                  '932w': member.photo.url,
                  '466w': member.photo.width466,
                  '212w': member.photo.width212
                },
                alt: member.photo.alt
              }
            : {
                url: '/fork/images/team-placeholder-212w.jpg',
                srcset: '/fork/images/team-placeholder-212w.jpg 212w',
                alt: 'Person with mask.'
              },
          link: {
            href: { pathname: '/user', query: { slug: `${encodeMemberSlug(member)}` } },
            asPath: `/team/${encodeMemberSlug(member)}`
          },
          status: member.status
        }));

      return <TeamList members={updatedMembers} link={getNextLinkProps(linkTo)} {...rest} />;

    case 'embeds_manualTeasers_BlockType':
      return (
        <ManualTeasers
          headline={props.headline}
          subline={props.subline}
          linkTo={props.linkTo}
          teasers={props.teasers}
        />
      );

    case 'embeds_entryTeasers_BlockType':
    case 'embeds_categoryTeasers_BlockType':
      const type = props.categories ? 'categories' : 'entries';

      const getIds = array => (array && array.length > 0 ? array.map(i => i.id) : []);

      const relatedToAll =
        type === 'entries'
          ? getIds([
              ...props.clientsCategories,
              ...props.companiesCategories,
              ...props.industriesCategories,
              ...props.locationsCategories,
              ...props.newsCategories,
              ...props.solutionsCategories,
              ...props.tasksCategories,
              ...props.departmentsCategories,
              ...props.eventsCategories
            ])
          : undefined;

      return (
        <AutomaticTeaserContainer
          id={props.id}
          headline={props.headline}
          subline={props.subline}
          linkTo={props.linkTo}
          limit={props.limit}
          customLinkText={props.customLinkText}
          showLoadMore={props.showLoadMore}
          showFilter={props.showFilter}
          featured={props.featured}
          sections={props.categories || props.sections}
          relatedToAll={relatedToAll}
          type={type}
        />
      );

    // Category Marquee
    case 'embeds_categoryMarquee_BlockType':
      // Check if component exists, currently not available in @ccg/fork
      if (!CategoryMarquee) return null;

      // Map trough categories which should be rendered to marquee and build links
      const rows = props.categories.map(group => {
        const categories = data.categories
          ? data.categories
              .concat(data.clientCategories)
              .filter(item => {
                return item.groupHandle === group;
              })
              .map(item => ({
                label: item.title,
                href: {
                  pathname: '/category',
                  query: { slug: item.slug, group: item.groupHandle }
                },
                asPath: `/${item.uri}`,
                logo:
                  item.logo && item.logo.length === 1
                    ? [
                        {
                          url: item.logo[0].url,
                          alt: item.logo[0].alt
                        }
                      ]
                    : null
              }))
          : [];

        return { links: categories };
      });

      return <CategoryMarquee headline={props.headline} rows={rows} />;

    // SoundCloud Embed
    case 'embeds_socialEmbed_BlockType':
      return (
        <SocialEmbed
          iframe={props.embedCode}
          text={data.cookieLayer.externalContentText}
          acceptLabel={data.cookieLayer.acceptExternalContentLabel}
        />
      );

    // Social Feed Embed
    case 'embeds_socialFeed_BlockType':
      return <SocialFeed feedType={props.feedType} feedId={props.feedId} />;

    // Forms
    case 'embeds_form_BlockType':
      return ACTIVE_SITE_HANDLE === 'fischerappelt' || ACTIVE_SITE_HANDLE === 'puk' ? (
        <Form
          {...formDataHelper(
            props,
            data.categories,
            data.entry.personioId,
            data.forms.personioTerms,
            data.forms.personioUploadsDescription,
            GOOGLE_RECAPTCHA_SITEKEY,
            t
          )}
        />
      ) : null;

    // Logo wall
    case 'embeds_logoWall_BlockType':
      return <LogoWall clients={props.clients} />;

    // Awards
    case 'embeds_awards_BlockType':
      const awards = props.awards.map(item => ({
        id: item.id,
        logo: [
          {
            url: item.award[0].awardImage[0].url,
            alt: item.award[0].awardImage[0].alt
          }
        ],
        year: item.year,
        category: item.category
      }));

      return <LogoWall clients={awards} />;

    // Tag Teasers
    case 'embeds_tagTeasers_BlockType':
      return <TagTeaser headline={props.headline} teasers={props.tagTeaser} />;

    // Text Teasers
    case 'embeds_textTeasers_BlockType':
      console.log(props.textTeaser.map(item => item.teaser.element));
      const getPath = type => {
        const types = {
          category: 'category',
          standard_standard_Entry: 'standard',
          jobs_jobs_Entry: 'job',
          cases_cases_Entry: 'case',
          news_news_Entry: 'news'
        };

        if (type.includes('Category')) {
          return types.category;
        }

        return types[type];
      };

      return (
        <TextTeasers
          headline={props.headline}
          teasers={props.textTeaser.map(item => ({
            ...item,
            link: {
              href: {
                pathname: `/${getPath(item.teaser.element.__typename)}`,
                query: { slug: item.teaser.element.slug, group: item.teaser.element.groupHandle }
              },
              asPath: `/${item.teaser.element.uri}`
            },
            cta: item.teaser.customText || t('teaser.read')
          }))}
        />
      );
    default:
      return null;
  }
};

Embed.propTypes = { t: PropTypes.func.isRequired, item: PropTypes.shape().isRequired };

export default withTranslation('common')(Embed);
