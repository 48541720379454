import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import checkLogin from '../helper/login';

export const LoginContext = React.createContext();

// to test on local use http://craft-ccg.localhost:5000/
const LoginProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    isGuest: true,
    userGroups: [],
    username: '',
    error: ''
  });

  useEffect(() => {
    // mounted
    const handleLogin = async () => {
      const data = await checkLogin();
      setUserData(prev => ({ ...prev, ...data }));
    };

    handleLogin();
  }, []);

  return <LoginContext.Provider value={{ userData }}>{children}</LoginContext.Provider>;
};

LoginProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const LoginConsumer = LoginContext.Consumer;

export default LoginProvider;
