/* eslint-disable no-shadow */
import React, { useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';

import { DataContext } from '@ccg/core';
import { TeaserOverview, TeaserContainer } from '@components';

import ManualEntries from '../../../graphql/queries/teaserContainer/manual.graphql';

import { getNextLinkProps } from '../../../helper';
import DynamicTeaser from './subcomponents/DynamicTeaser';

const ManualTeasers = ({ headline, subline, linkTo, teasers }) => {
  const { site } = useContext(DataContext);

  /**
   * Seperate ids from teasers into entry and category ids
   * to set query variables based on type
   */
  const getTeaserIds = useCallback(() => {
    const categoryIds = teasers
      .filter(item => item.teaser.type === 'category')
      .map(item => item.teaser.element && item.teaser.element.id);
    const entryIds = teasers
      .filter(item => item.teaser.type === 'entry')
      .map(item => item.teaser.element && item.teaser.element.id);

    return { categoryIds, entryIds };
  }, [teasers]);

  /**
   * Query categories and entries based on the teaser ids
   * inside manual teasers matrix block
   */
  const { data, loading } = useQuery(ManualEntries, { variables: { site, ...getTeaserIds() } });

  /**
   * Sort queried teaser data based on the initial given order
   */
  const sortTeasers = useCallback(() => {
    const sortedIds = teasers.map(item => item.teaser.element && item.teaser.element.id);
    const manualTeaser = [];

    sortedIds.forEach(id => {
      const category = data && data.categories.find(i => i.id === id);
      const entry = data && data.entries.find(i => i.id === id);

      // allow custom link text
      if (entry) {
        const entryLink = teasers.find(item => item.teaser.element.id === entry.id);

        if (entryLink && entryLink.teaser.title) {
          entry.title = entryLink.teaser.title;
        }
        if (entryLink && entryLink.teaser.customText) {
          entry.cta = entryLink.teaser.customText;
        }
      }
      if (category) {
        const categoryLink = teasers.find(item => item.teaser.element.id === category.id);
        if (categoryLink && categoryLink.teaser.title) {
          category.title = categoryLink.teaser.title;
        }
        if (categoryLink && categoryLink.teaser.customText) {
          category.cta = categoryLink.teaser.customText;
        }
      }

      if (category) return manualTeaser.push(category);
      manualTeaser.push(entry);
    });

    return manualTeaser;
  }, [data]);

  const sortedTeasers = sortTeasers();

  return (
    <>
      {(headline || subline) && (
        <TeaserOverview headline={headline} subline={subline} link={getNextLinkProps(linkTo)} />
      )}

      {data && sortedTeasers.length > 0 && (
        <TeaserContainer filtering={loading}>
          {sortedTeasers.map(
            (item, index) => item && <DynamicTeaser key={item.id} index={index + 1} {...item} />
          )}
        </TeaserContainer>
      )}
    </>
  );
};

ManualTeasers.propTypes = {
  headline: PropTypes.string,
  subline: PropTypes.string,
  linkTo: PropTypes.shape(),
  teasers: PropTypes.shape().isRequired
};

ManualTeasers.defaultProps = {
  headline: null,
  subline: null,
  linkTo: null
};

export default ManualTeasers;
