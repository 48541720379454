import fetch from 'isomorphic-unfetch';

// to test on local use http://craft-ccg.localhost:5000/
const checkLogin = async (domain = '', headers = null) => {
  const url = `${domain}/api/ccg/users/session-info?dontExtendSession=1`;

  const fetchHeaders = headers || {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: fetchHeaders,
      credentials: 'same-origin'
    });

    if (response.status === 200) {
      const data = await response.json();
      return data;
    }

    console.log('Login failed.');
    return { error: response.statusText };
  } catch (error) {
    console.error('You have an error in your code or there are Network issues.', error);

    const { response } = error;
    return { error: response ? response.statusText : error.message };
  }
};

export default checkLogin;
