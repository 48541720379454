export const createEmbeds = (copyString, embedsArray = []) => {
  const divider = '<!--pagebreak-->';
  const newCopyString = copyString || '';

  // Remove whitespace and breaks from string
  const removeWhitespace = newCopyString.replace(/\r?\n|\r/g, '');
  const removeBreaks = removeWhitespace.replace('<p><br /></p>', '');

  // Split copy at pagebreak divider
  const splittedCopy = removeBreaks.split(/(<!--pagebreak-->)/);

  // Filter out empty strings
  const filteredCopy = splittedCopy.filter(content => content !== '');

  // Build new embeds object
  const newEmbeds = filteredCopy.map(content => {
    // If is divider return to filter out later
    if (content === divider) return content;

    return {
      __typename: 'embeds_text_BlockType',
      content
    };
  });

  // Merge arrays
  let embedIndex = 0;

  newEmbeds.forEach((content, index) => {
    // Check if content is a divider and embeds lenght is bigger than current index
    // Else remove unused dividers
    if (content === divider && embedsArray.length > embedIndex) {
      newEmbeds.splice(index, 1, embedsArray[embedIndex]);
      embedIndex += 1;
    } else if (content === divider) {
      newEmbeds.splice(index, 1);
    }
  });

  return newEmbeds.filter(embed => typeof embed !== 'string');
};

export const updateEmbedsWithFact = array => {
  const newEmbeds = [];

  array.forEach((item, index) => {
    if (
      item.__typename === 'embeds_text_BlockType' &&
      array[index + 1] &&
      array[index + 1].__typename === 'embeds_fact_BlockType'
    ) {
      newEmbeds.push({
        ...item,
        __typename: 'embeds_fact_BlockType',
        fact: array[index + 1].fact
      });
    } else if (item.__typename !== 'embeds_fact_BlockType') {
      newEmbeds.push(item);
    }
  });

  return newEmbeds;
};
